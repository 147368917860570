<template>
    <header class="nav-bar" :class="{ 'is-mobile' : isMobileScreen, 'is-mobile-menu-open' : isMobileMenuOpen }">
        <div class="container-fluid">
            <nav>
                <div v-wow="{ 'animation-name': 'fadeInDown','animation-duration': '1.5s', 'animation-delay' : '0.3s'}" class="logo">
                    <router-link :to="{ name: 'landing' }" class="logo">
                        <img src="@assets/img/logo-memod.svg" class="img-fluid" alt="logo Memod">
                    </router-link>
                </div>
                <search-bar v-if="isLoggedIn && !$route.name.includes('search') && !isMobileScreen" />
                <div v-if="isMobileScreen && !isLoggedIn" class="open-menu" @click="isMobileMenuOpen = !isMobileMenuOpen">
                    <transition name="fade" mode="out-in">
                        <img v-if="isMobileMenuOpen" src="@assets/img/icons/x-white.svg">
                    </transition>
                    <transition name="fade" mode="out-in">
                        <img v-if="!isMobileMenuOpen" src="@assets/img/icons/hamburger-menu.svg">
                    </transition>
                </div>
                <div class="nav-links">
                    <template v-if="!isLoggedIn">
                        <router-link class="nav-link" :to="{ name: 'landing' }">
                            What is Memo’d
                        </router-link>
                        <router-link class="nav-link" :to="{ name: 'web-app-home-browse-memos' }">
                            Browse Memos
                        </router-link>
                        <router-link class="nav-link" :to="{ name: 'founders-landing' }">
                            Founding Creators Program
                        </router-link>
                        <router-link class="nav-link" :to="{ name: 'about-us' }">
                            About us
                        </router-link>
                        <button
                            v-if="!$route.name.includes('founders')"
                            class="nav-link btn btn-primary"
                            type="button"
                            @click="handleAuthModal"
                        >
                            Sign In
                        </button>
                        <button v-if="$route.name == 'founders-landing'" class="nav-link btn btn-primary" @click="handleFoundersInvitation">
                            Start now
                        </button>
                    </template>
                    <template v-if="isLoggedIn && !isMobileScreen">
                        <router-link
                            v-for="(navigation, index) in webNavigationLinks"
                            :key="index"
                            v-intro-autostart="showIntro"
                            v-intro-autostart.config="{ showStepNumbers: false, prevLabel: 'Previous', nextLabel: 'Next', hidePrev: true, hideNext: true }"
                            v-intro-autostart:on.complete="onIntroCompleted"
                            v-intro-autostart:on.exit="onExit"
                            v-intro="navigation.introText"
                            v-intro-position="'bottom-left-aligned'"
                            v-intro-tooltip-class="`desktop step-${index + 1}`"
                            class="nav-link web-app-link"
                            :to="{ name: navigation.routeName, params: navigation.routeParams }"
                            @click.native="toggleDraftsList"
                        >
                            <div class="icon">
                                <img :src="navigation.icon" :alt="navigation.name">
                            </div>
                            <span class="d-none d-xl-block">{{ navigation.name }}</span>
                        </router-link>
                        <div class="user-options">
                            <router-link :to="{ name: 'web-app-profile', params: { displayName: userData.displayname } }" class="user-link">
                                <img :src="userData.photo? userData.photo.url : require('@assets/img/icons/avatar.png')">
                            </router-link>
                            <v-popover>
                                <div class="tooltip-target b3">
                                    <img class="chevron" src="@assets/img/icons/chevron-down.svg">
                                </div>

                                <template slot="popover">
                                    <div class="popover-options">
                                        <router-link :to="{ name: 'web-app-profile-settings', params: { displayName: userData.displayname } }" class="popover-option">
                                            <span>Settings</span>
                                            <img src="@assets/img/icons/settings.svg" alt="Settings">
                                        </router-link>
                                        <a href="javascript:void(0)" class="popover-option" @click="logout">
                                            <span>Sign out</span>
                                            <img src="@assets/img/icons/sign-out.svg" alt="Logout">
                                        </a>
                                    </div>
                                </template>
                            </v-popover>
                        </div>
                    </template>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "NavBar",
    components: {
        SearchBar: () => import(/* webpackChucnkName: "search-bar" */ "@/components/organisms/search-bar/")
    },
    props: {
        isValidInvitation: {
            type: Boolean,
            default: false
        },
        showIntro: {
            type: Boolean,
            default: false
        },
        navigationLinks: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            isMobileMenuOpen: false,
            webNavigationLinks: []
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data,
            isMobileScreen: state => state.Application.isMobileScreen
        })
    },
    created() {
        this.setWebNavigationLinks();
    },
    methods: {
        setWebNavigationLinks() {
            const webNavigationLinks = [...this.navigationLinks];
            const profileNavigationLinkIndex = webNavigationLinks.findIndex(navigationLink => navigationLink.routeName == "web-app-profile");
            webNavigationLinks.splice(profileNavigationLinkIndex, 1);
            this.webNavigationLinks = webNavigationLinks;
        },
        handleAuthModal() {
            this.isMobileMenuOpen = false;

            // only redirect the user to the home feed after login if ther user is not in the memod reader
            const redirectRoute = this.$route.name.includes("memod-reader") ? "" : { name: "web-app" }
            this.$modal.show("auth-modal", { allowClose: true, showSignIn: true, redirectRoute });
        },
        handleFoundersInvitation() {
            this.isMobileMenuOpen = false;
            this.$modal.show(this.isValidInvitation ? "get-started-invited" : "get-started-no-invited");
        },
        onIntroCompleted() {
            this.$emit("on-intro-completed");
        },
        onExit() {
            this.$amplitude.logEvent("skip-onboarding");
            this.$emit("on-exit");
        },
        async logout() {
            this.$amplitude.logEvent("Logout");
            this.$emit("is-loading", true);

            await this.$router.push({ name: "landing" });
            await this.$store.dispatch("Application/resetGlobalData");
            
            window.location.reload();
        },
        toggleDraftsList() {
            this.$store.dispatch("Application/toggleDraftsList", false);
        }
    }
}
</script>
<style lang="scss" scoped>
.nav-bar {
    background-color: #1c1c1c;
    border-bottom: 1px solid #38383A;
    padding: 10px 0;

    > .container-fluid {
        padding-right: 54px;
        padding-left: 54px;

        @media(max-width: $md) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 170px;

            @media(max-width: $sm) {
                width: 150px;
            }
        }

        .search-bar {
            margin-left: auto;
            margin-right: 20px;

            @media(max-width: $lg) {
                margin-left: 20px;
            }
        }

        .nav-links {
            display: flex;

            .nav-link {
                @extend .body-text;
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;

                span {
                    margin-left: 7px;
                    font-size: 20px;
                }

                &:not(.web-app-link).router-link-exact-active {
                    color: $primary-color;
                }

                &.web-app-link {
                    &.router-link-active {
                        background-color: #3A3A3C;
                        border-radius: 75px;

                        .icon {
                            background: #2C2C2E;
                        }
                    }
                }
            }

            a.nav-link {
                padding: 0 17px;

                @media(max-width: $lg) {
                    padding: 0 7px;
                }
            }

            button.nav-link {
                padding: 5px 20px;
            }

        }

        .user-options {
            display: flex;
            align-items: center;

            .user-link {
                width: 44px;
                height: 44px;
                margin-left: 15px;
                background-color: #1C1C1E;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                cursor: pointer;
                color: white;
                text-decoration: none;
                font-weight: 400;
                font-size: 16px;
                overflow: hidden;
                z-index: 99;

                img {
                    width: 100%;
                }
            }

            .tooltip-target {
                cursor: pointer;
            }
        }
    }

    .open-menu {
        width: 45px;
        height: 45px;

        img {
            position: absolute;
        }
    }

    &.is-mobile {
        nav {
            .logo {
                position: relative;
                z-index: 9999;
            }

            .open-menu {
                position: relative;
                z-index: 9999;
            }

            .nav-links {
                margin-top: 50px;
                position: fixed;
                top: 0;
                z-index: 99;
                width: 100%;
                left: 0;
                background-color: #1c1c1c;
                flex-direction: column;
                overflow: hidden;
                height: 0vh;
                transition: height .3s;

                .nav-link {
                    margin-top: 60px;

                    &.btn {
                        align-self: flex-start;
                        margin-left: 17px;
                    }
                }
            }
        }

        &.is-mobile-menu-open {

            nav {
                .nav-links {
                    height: 100vh;
                }
            }
        }
    }

    .tooltip-target {
        display: flex;
        align-items: center;

        .chevron {
            margin-left: 10px;
        }
    }

    &.introjs-fixParent {
        z-index: 9999999 !important;
    }
}
</style>
